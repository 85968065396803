import { endpointKeys } from '../endpoints'
import RestService from './restService'

export const getAdvancedLandingPageDealService = async adReference => {
  return await RestService.get(endpointKeys.advancedLandingPageDeal, { adReference })
}

export const getPageContentListService = async params => {
  return await RestService.get(endpointKeys.pageContentList, { ...params })
}

export const getPageContentItemService = async params => {
  return await RestService.get(endpointKeys.pageContent, { ...params })
}

export const getFaqArticleContentService = async () => {
  return await RestService.get(endpointKeys.faqArticles)
}

export const getCareersContentService = async () => {
  return await RestService.get(endpointKeys.careers)
}

export const getCustomerTestimonialsService = async () => {
  return await RestService.get(endpointKeys.testimonials)
}
