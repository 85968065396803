import Image from 'next/image'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'

import { constants } from '../../../lib/constants'
import { getCustomerTestimonialsService } from '../../../lib/services/pageService'
import { isVowel } from '../../../lib/utilities/page'

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  arrows: false,
  variableWidth: false,
  adaptiveHeight: false,
  fade: true,
}

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    getCustomerTestimonialsService().then(res => {
      setTestimonials(res)
    })
  }, [])

  return (
    <section className="section-customer-testimonials">
      <div className="grid__container">
        <div className="grid__row">
          <Slider {...settings}>
            {testimonials.map(testimonial => (
              <div
                key={testimonial.id}
                className="testimonial">
                <div className="testimonial__image">
                  <Image
                    src={`${constants.imageHost.assets}/testimonials/${testimonial.image}`}
                    height="240"
                    width="240"
                    alt={`${testimonial.name} and their ${testimonial.manufacturer}`}
                  />
                </div>
                <p className="testimonial__description">{testimonial.description}</p>
                {testimonial.manufacturer ? (
                  <p className="testimonial__name">
                    {testimonial.name} leased
                    {isVowel(testimonial.manufacturer.charAt(0)) ? ' an ' : ' a '}
                    <span>{testimonial.manufacturer}</span>
                  </p>
                ) : (
                  <p className="testimonial__name">{testimonial.name}</p>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
